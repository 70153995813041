<template>
  <div
    class="main"
    :class="{
      hide: system.hideSidebar === 0,
      mini: system.miniSidebar === 1
    }"
  >
    <div class="sidebar">
      <Sidebar />
    </div>
    <div class="body">
      <BodyTop :address="currentAddress"/>
      <div class="body-main">
        <div class="content">
          <div class="content-view">
              <div>
                <el-card style="border-radius: 0">
                  <div slot="header">
                    <span>普通功能</span>
                  </div>
                  <div class="demo">
                    <el-row :gutter="20">
                      <el-col :span="1.5">
                        <el-button type="primary" @click="batch_select()">查询未收取收益 {{all_count}}</el-button>
                      </el-col>
                    </el-row>
                  </div>
                </el-card>
                <br />
                <el-card style="border-radius: 0">
                  <div slot="header">
                    <span>批量收取工资</span>
                  </div>
                  <div class="demo">
                  
                    <el-row :gutter="20">
                      <el-col :span="1.5">
                        <el-button type="primary" @click="milk_staking('milk')">牛奶站 {{milk_count}}</el-button>
                      </el-col>
                      <el-col :span="1.5">
                          <el-button type="primary" @click="milk_staking('warehouseStaking')">仓 库  {{staking_warehouseStaking_count}}</el-button>
                      </el-col>
                      <el-col :span="1.5">
                          <el-button type="primary" @click="milk_staking('energyStationStaking')">能源中心  {{staking_energyStationStaking_count}}</el-button>
                      </el-col>
                      <el-col :span="1.5">
                          <el-button type="primary" @click="milk_staking('gymStaking')">健身房 {{staking_gymStaking_count}}</el-button>
                      </el-col>
                      <el-col :span="1.5">
                          <el-button type="primary" @click="milk_staking('darkCastleStaking')">黑暗城堡 {{staking_darkCastleStaking_count}}</el-button>
                      </el-col>
                      <el-col :span="1.5">
                          <el-button type="primary" @click="milk_staking('postOfficeStaking')">邮局 {{staking_postOfficeStaking_count}}</el-button>
                      </el-col>
                      <el-col :span="1.5">
                          <el-button type="primary" @click="milk_staking('airportStaking')">机场 {{staking_airportStaking_count}}</el-button>
                      </el-col>
                      

                    </el-row>
                   
                  </div>
                </el-card>
                <br/>
                <el-card style="border-radius: 0">
                  <div slot="header">
                    <span>待开发</span>
                  </div>
                  <div class="demo">
                    <el-row :gutter="20">
                      <el-col :span="1.5">
                        <el-button type="primary" disabled>帅选合格卡片</el-button>
                      </el-col>
                      <el-col :span="1.5">
                          <el-button type="primary" disabled>批量退休</el-button>
                      </el-col>
                      <el-col :span="1.5">
                          <el-button type="primary" disabled>批量升级</el-button>
                      </el-col>
                      <el-col :span="1.5">
                          <el-button type="primary" disabled>批量打工</el-button>
                      </el-col>
                      <el-col :span="1.5">
                          <el-button type="primary" disabled>生成 SSR Baby</el-button>
                      </el-col> 
                      
                    </el-row>
                  </div>
                </el-card>
                <br />
                <el-card style="border-radius: 0">
                  <div slot="header">
                    <span>账户角色信息</span>
                  </div>
                  <div class="demo" >
                    <el-table
                          :data="tableData" style="width: 100%">
                      <el-table-column
                          prop="NFTID" label="NFTID" width="800">
                      </el-table-column>
                      <el-table-column prop="name" label="Baby" width="180"> 

                      </el-table-column>
                      <el-table-column prop="address" label="属性"> 

                      </el-table-column>
                    </el-table>
                  </div>
                </el-card> 
              </div>
          </div>
        </div>
        <Footer/>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Sidebar from "../layout/Sidebar.vue";
import BodyTop from "../layout/BodyTop.vue";
import Footer from "../layout/Footer.vue";
import { ethers } from 'ethers';
import contracts from '../../contracts/contracts';
import { refAddress } from '../../config';
import { getNFTStakingContract , getGameBreedContract } from '../../contracts';
export default {
  name: "App",
  components: { Sidebar, BodyTop,Footer },
  data() {
    return {
      currentAddress: "",
      provider:null,
      signer:null,
      NFTStakingContract:null,
      gameBreedContract:null,
      milk_count:null,
      staking_warehouseStaking_count:null,
      staking_energyStationStaking_count:null,
      staking_gymStaking_count:null,
      staking_darkCastleStaking_count:null,
      staking_postOfficeStaking_count:null,
      staking_airportStaking_count:null,
      all_count:null,
      tableData: [{
            NFTID: '6319604186963219164725306450891761565302411393813433019535528806035715937149',
            name: 'BABY',
            address: '0x0000000000000000'
          }, {
            NFTID: '6319604186963219164725306450891761565302411393813433019535528806035715937149',
            name: 'BABY',
            address: '0x0000000000000000'
          },{
            NFTID: '6319604186963219164725306450891761565302411393813433019535528806035715937149',
            name: 'BABY',
            address: '0x0000000000000000'
          },{
            NFTID: '6319604186963219164725306450891761565302411393813433019535528806035715937149',
            name: 'BABY',
            address: '0x0000000000000000'
          }]

    };
  },
  mounted(){
    let _this=this;
    _this.provider  = ethers.providers.Web3Provider;
    _this.signer  = ethers.providers.JsonRpcSigner;
    _this.NFTStakingContract = ethers.Contract;
    _this.gameBreedContract = ethers.Contract;
    _this.provider = new ethers.providers.Web3Provider(window.ethereum);
    
    _this.provider.send('eth_requestAccounts', []).then(res=>{
        _this.signer = _this.provider.getSigner();
        _this.currentAddress=res[0];
    });
    window.ethereum.on('accountsChanged', function (accounts) {
        _this.signer = _this.provider.getSigner();
        _this.currentAddress=accounts[0];
    })
    
  },
  computed: mapState(["system"]),
  methods: {
    //批量查询收益
    batch_select()
    {
        let _this=this;
        _this.NFTStakingContract = getNFTStakingContract(contracts.staking_milkStationStaking, _this.provider);
        this.NFTStakingContract.stakeInfoOfUser(_this.currentAddress).then(res=>{
          if (res) {
            _this.milk_count = parseInt(res[1].toString()) / (1000000000000000000).toString();
          } else {
            _this.milk_count = "";
          }
        });

        _this.NFTStakingContract = getNFTStakingContract(contracts.staking_warehouseStaking, _this.provider);
        this.NFTStakingContract.stakeInfoOfUser(_this.currentAddress).then(res=>{
          if (res) {
            _this.staking_warehouseStaking_count = parseInt(res[1].toString()) / (1000000000000000000).toString();
          } else {
            _this.staking_warehouseStaking_count = "";
          }
        });
        _this.NFTStakingContract = getNFTStakingContract(contracts.staking_energyStationStaking, _this.provider);
        this.NFTStakingContract.stakeInfoOfUser(_this.currentAddress).then(res=>{
          if (res) {
            _this.staking_energyStationStaking_count = parseInt(res[1].toString()) / (1000000000000000000).toString();
          } else {
            _this.staking_energyStationStaking_count = "";
          }
        });
        _this.NFTStakingContract = getNFTStakingContract(contracts.staking_gymStaking, _this.provider);
        this.NFTStakingContract.stakeInfoOfUser(_this.currentAddress).then(res=>{
          if (res) {
            _this.staking_gymStaking_count = parseInt(res[1].toString()) / (1000000000000000000).toString();
          } else {
            _this.staking_gymStaking_count = "";
          }
        });

        _this.NFTStakingContract = getNFTStakingContract(contracts.staking_darkCastleStaking, _this.provider);
        this.NFTStakingContract.stakeInfoOfUser(_this.currentAddress).then(res=>{
          if (res) {
            _this.staking_darkCastleStaking_count = parseInt(res[1].toString()) / (1000000000000000000).toString();
          } else {
            _this.staking_darkCastleStaking_count = "";
          }
        });

        _this.NFTStakingContract = getNFTStakingContract(contracts.staking_postOfficeStaking, _this.provider);
        this.NFTStakingContract.stakeInfoOfUser(_this.currentAddress).then(res=>{
          if (res) {
            _this.staking_postOfficeStaking_count = parseInt(res[1].toString()) / (1000000000000000000).toString();
          } else {
            _this.staking_postOfficeStaking_count = "";
          }
        });

        _this.NFTStakingContract = getNFTStakingContract(contracts.staking_airportStaking, _this.provider);
        this.NFTStakingContract.stakeInfoOfUser(_this.currentAddress).then(res=>{
          if (res) {
            _this.staking_airportStaking_count = parseInt(res[1].toString()) / (1000000000000000000).toString();
            _this.all_count = _this.milk_count + _this.staking_warehouseStaking_count + _this.staking_energyStationStaking_count + _this.staking_gymStaking_count +  _this.staking_darkCastleStaking_count + _this.staking_postOfficeStaking_count+ _this.staking_airportStaking_count;
          } else {
            _this.staking_airportStaking_count = "";
            _this.all_count = _this.milk_count + _this.staking_warehouseStaking_count + _this.staking_energyStationStaking_count + _this.staking_gymStaking_count +  _this.staking_darkCastleStaking_count + _this.staking_postOfficeStaking_count+ _this.staking_airportStaking_count;
          }
        });
        
    },
    //生成SSR baby
    generateSSR()
    {
        let _this=this;
        _this.gameBreedContract = getGameBreedContract(_this.signer);
        _this.gameBreedContract.breed(refAddress).then()
    },
    //收取牛奶站工资
    milk_staking(NFTStaking_address)
    {
        let _this=this;
        switch (NFTStaking_address) {
         case 'milk':
           _this.NFTStakingContract = getNFTStakingContract(contracts.staking_milkStationStaking, _this.signer);
           break;
         case 'warehouseStaking':
           _this.NFTStakingContract = getNFTStakingContract(contracts.staking_warehouseStaking, _this.signer);
           break;
         case 'energyStationStaking':
           _this.NFTStakingContract = getNFTStakingContract(contracts.staking_energyStationStaking,_this.signer);
           break;
         case 'gymStaking':
           _this.NFTStakingContract = getNFTStakingContract(contracts.staking_gymStaking, _this.signer);
           break;
         case 'darkCastleStaking':
           _this.NFTStakingContract = getNFTStakingContract(contracts.staking_darkCastleStaking, _this.signer);
           break;
         case 'postOfficeStaking':
           _this.NFTStakingContract = getNFTStakingContract(contracts.staking_postOfficeStaking, _this.signer);
           break;
         case 'airportStaking':
           _this.NFTStakingContract = getNFTStakingContract(contracts.staking_airportStaking, _this.signer);
           break;
         default:
           break;
       }
          _this.NFTStakingContract.harvestAll().then()
        
    },
  },

};
</script>
<style lang="scss">
@import "../../assets/css/variables.scss";
.main {
  width: 100vw;
  .sidebar {
    width: $--sidebar-width;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 10;
    transition: width 0.3s ease-in-out;
  }
  .body {
    margin-left: $--sidebar-width;
    position: relative;
    height: 100vh;
    transition: margin-left 0.3s ease-in-out;
  }
}
.mini {
  .sidebar {
    width: 64px !important;
    .sidebar-top {
      background-color: #0086f1 !important;
    }
    .icon-left {
      transform: rotate(180deg);
    }
  }
  .body {
    margin-left: 64px !important;
  }
}
.hide {
  .sidebar {
    width: 0 !important;
  }
  .body {
    margin-left: 0 !important;
  }
}
.body-main {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #ecf0f5;
  height: calc(100vh - #{$--top-height});
  .content {
    .content-view {
      padding: 6px;
      min-height: calc(100vh - #{$--top-height} - #{$--top-height});
    }
  }
}
</style>
