<template>
  <div class="sidebar-content">
    <div class="sidebar-top">
      <img
        class="logo"
        v-if="system.miniSidebar === 1"
        src="../../assets/images/whiteLogo.svg"
        alt=""
      />
      <span v-else>{{ GlobalCfg.siteName }}</span>
    </div>
    <div class="sidebar-menu">
      
    </div>
    <div class="sidebar-bottom" @click="$store.commit('MINI_SIDEBAR_TOGGLE')">
      <div class="icon-left">
        <i class="el-icon-back"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "Sidebar",
  data() {
    return {};
  },
  methods: {},
  computed: { ...mapState(["system"]), ...mapGetters(["displayMenu"]) }
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/variables.scss";
.sidebar-content {
  overflow: hidden;
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;
  .sidebar-top {
    width: 100%;
    color: #fff;
    background-color: mix(#000, $--color-primary, 10%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: $--top-height;
    span {
      white-space: nowrap;
    }
    .logo {
      width: 38px;
    }
  }
  .sidebar-menu {
    width: 100%;
    height: calc(100vh - #{$--top-height} - #{$--top-height});
    overflow-y: auto;
    overflow-x: hidden;
  }
  .sidebar-bottom {
    position: relative;
    width: 100%;
    height: $--top-height;
    background-color: #0086f1;
    color: #fff;
    cursor: pointer;
    .icon-left {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      width: 64px;
      height: 100%;
      font-size: 20px;
      transition: all 0.3s ease-in-out;
    }
  }
}
</style>
