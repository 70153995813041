export const refAddress = '0xE039f82901DEa79dA9aD639779962a1A57777777';

export const MINT_FEE = {
  BABY: 50,
  BNB: 0.1,
};

export const TOKEN_DECIMAL = {
  BABY: 18,
  BNB: 18,
  MILK: 18,
};

export const LEVEL_COST_BABY = 4;
export const LEVEL_LOST_BABY = 5;

export const LEVEL_UP_RATE = {
  '1': 100,
  '2': 100,
  '3': 100,
  '4': 100,
  '5': 80,
  '6': 70,
  '7': 60,
  '8': 50,
  '9': 50,
  '10': 50,
  '11': 50,
};
