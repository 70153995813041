const contracts = {
  invite: '0x0D86673b71F96dbb068478fa75a5EB5DF0CDf8ac',
  baby: '0x53e562b9b7e5e94b81f10e96ee70ad06df3d2657',
  bnb: '',
  milk: '0xBf37f781473f3b50E82C668352984865eac9853f',
  gameBreed: '0x0A32f45a6897d8f4Bf8aD0a45030b49517caf72b',
  gameLevel: '0x36f1aE8e031B43Bf691D02B4AcA0eC53e48504a0',
  gameSetting: '0xa1c41256B25a2cc87ea71d8dE7B37dF7d25e0515',
  role_bitcoinHolder: '0xb0bB0C5D408c999eaaEAa408874B3b8535BC8fC4',
  role_hacker: '0xC4594aFAD15AB2d76440533023Ff9c9839cbAe9D',
  role_altcoinCollector: '0x996877bfc830ee52b786675d211fdffd2493a0ea',
  role_hardcoreMiner: '0x6741626ed9A06702D3daD18bDbE75983b66f38cC',
  role_deFiFarmer: '0x5bC2F0ECe4F7A85F5F323f7FdC80fF1028F417d4',
  role_scammer: '0xd3f530521DAAaEB3720C8d0E797Ed38cA1f50305',
  role_spotTrader: '0x90b5f4A0ab7dE9fC3028D2C0b67348aE1a4D3C25',
  role_futuresLover: '0xb8788bD7eC1a945585B5E76C473a6A7729994386',
  role_cryptoOG: '0x2a2635738E457cb81441e6a05FE690A69c2eF180',
  role_cryptoBABY: '0xa99a4FAbB9A945F60FB96d6fDf8dE7d2EF1e8C36',
  role_bonusHunter: '0x419cee63cb9206B4df853dd6500eE522b154db49',
  role_shiller: '0x1D44060BBb47D4c217c92CB3edfFA0d33d7cF97c',
  place_milkStation: '0x403A5D5D688EF221a0D7946B773c1314E82D80D9',
  staking_milkStationStaking: '0x9447e3eD2A23572F7Be359216321f7e67B364BaC',
  place_warehouse: '0x18A29bE4e131599B90eed8128a0497ca9DEB0f85',
  staking_warehouseStaking: '0xb8272f417c27CD7643B2E4357ce5cab65f376e38',
  place_energyStation: '0x878d94D0D69cd0629D137Cc08B0f55e63b46073A',
  staking_energyStationStaking: '0xd484911B843e61369b7a320949449a76e2366e62',
  place_gym: '0x664109833340eDb2eB7cC28bC9f244AC03a09962',
  staking_gymStaking: '0xfaB9DA2B15D486FA15624415E1883eEFFeEfD403',
  place_darkCastle: '0xA1417f950a3aAADF5843511a21bf8E343EEd7297',
  staking_darkCastleStaking: '0xD1dB401a1508A7da796d6C7103B22409b7D037fa',
  place_postOffice: '0xf8f24dA135775bcB974E8d525f1E409729313677',
  staking_postOfficeStaking: '0xde157716C077137525AF13882f3c725191FF60bf',
  place_airport: '0x56BF6baF3903c239a2dD0bF3f51eD895084c6226',
  staking_airportStaking: '0x4b0948EBd6410F448CF1FDaa1BC0c90d748a53EF',
  nftProxy: '0x6c7C71c36921D3227eCF27d34f9d8d4C53cDC8Df',
  treasury: '0x0000000000000000000000000000000000000000',
  masterChef: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  sousChef: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  lotteryV2: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  multiCall: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
  pancakeProfile: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
  pancakeRabbits: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
  bunnyFactory: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
  claimRefund: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
  pointCenterIfo: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
  bunnySpecial: '0xFee8A195570a18461146F401d6033f5ab3380849',
  tradingCompetition: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
  easterNft: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
  cakeVault: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
  predictions: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
  chainlinkOracle: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
  bunnySpecialCakeVault: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
  bunnySpecialPrediction: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
  bunnySpecialLottery: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
  farmAuction: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
  AnniversaryAchievement: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
  nftsMarket: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
  nftSale: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
  pancakeSquad: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
  nftMarket:  '0x1F6bc601fDe821E0079c89529c79e3C616Da7E22',
};

export default contracts;
