import { ethers } from 'ethers';
import contracts from './contracts';
import babyAbi from './abis/baby.json';
import BNBAbi from './abis/bnb.json';
import milkAbi from './abis/b_milk.json';
import gameBreedAbi from './abis/gameBreed.json';
import gameSettingAbi from './abis/gameSetting.json';
import MultiCallAbi from './abis/Multicall.json';
import gameLevelupAbi from './abis/gameLevel.json';
import nftProxyAbi from './abis/nftProxy.json';
import nftStakingAbi from './abis/nftStaking.json';
import nftAbi from './abis/role_gameNFT.json';

export const getBabyContract = (signer= ethers.Signer | ethers.providers.Provider) => {
  return new ethers.Contract(contracts.baby, babyAbi, signer);
};

export const getBNBContract = (signer =  ethers.Signer | ethers.providers.Provider) => {
  return new ethers.Contract(contracts.bnb, BNBAbi, signer);
};

export const getMilkContract = (signer =  ethers.Signer | ethers.providers.Provider) => {
  return new ethers.Contract(contracts.milk, milkAbi, signer);
};

export const getGameBreedContract = (signer =  ethers.Signer | ethers.providers.Provider) => {
  return new ethers.Contract(contracts.gameBreed, gameBreedAbi, signer);
};

export const getGameSettingContract = (signer =  ethers.Signer | ethers.providers.Provider) => {
  return new ethers.Contract(contracts.gameSetting, gameSettingAbi, signer);
};

export const getMulticallContract = (signer =  ethers.Signer | ethers.providers.Provider) => {
  return new ethers.Contract(contracts.multiCall, MultiCallAbi, signer);
};

export const getGameLevelContract = (signer =  ethers.Signer | ethers.providers.Provider) => {
  return new ethers.Contract(contracts.gameLevel, gameLevelupAbi, signer);
};

export const getGameNFTProxyContract = (signer =  ethers.Signer | ethers.providers.Provider) => {
  return new ethers.Contract(contracts.nftProxy, nftProxyAbi, signer);
};

export const getNFTStakingContract = (
  contractAddress,
  signer  = ethers.Signer | ethers.providers.Provider
) => {
  return new ethers.Contract(contractAddress, nftStakingAbi, signer);
};

export const getNFTContractByParams = (
  contractAddress,
  signer =  ethers.Signer | ethers.providers.Provider
) => {
  return new ethers.Contract(contractAddress, nftAbi, signer);
};
